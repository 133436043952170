import { useEffect, useState } from "react";
import MainLandingCard from "./MainLandingCard";
import MainLandingDetails from "./MainLandingDetails";
import { useNavigate } from "react-router-dom";
import { useAnimationControls } from "framer-motion";
import Navbar from "../../common/Navbar/Navbar";
import { useDispatch } from "react-redux";
import {
  setFormPopupState,
  setSelectedURL,
} from "../../../Redux/FormPopup-Slice";
import { replaceSpace } from "../../../Utils/UtilityFunctions/UtilityFunction";
import constants from "../../../Utils/Constants/constants";
import ParticleAnimation from "./ParticleAnimation";

const MainLandingComponent: React.FC<{
  landingDetails: any;
  buttonText: string;
  buttonURL: string;
  blogDetails: any;
  backgroundType: string;
  animationVisibility: boolean;
  pressReleaseDetails: any;
  advisoryReportDetails: any;
  backgroundImage: string;
}> = ({
  landingDetails = [],
  buttonText,
  buttonURL,
  blogDetails,
  backgroundType,
  animationVisibility = false,
  pressReleaseDetails,
  advisoryReportDetails,
  backgroundImage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appearControls = useAnimationControls();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [changed, setChanged] = useState(false);

  const updateIndex = async () => {
    setCurrentIndex(
      (currentIndex) => (currentIndex + 1) % landingDetails.length
    );
  };

  const animationControl = async () => {
    try {
      appearControls.start({
        opacity: [0, 1],
        transition: { duration: 2 },
      });
    } catch {}
  };

  useEffect(() => {
    animationControl();
  }, [currentIndex]);

  useEffect(() => {
    try {
      if (landingDetails && landingDetails.length > 0) {
        appearControls.start({
          opacity: [0, 1],
          transition: { duration: 1.5 },
        });
        setChanged(true);

        const intervalId = setInterval(updateIndex, 4000);
        return () => clearInterval(intervalId); // Clean up the interval timer
      }
    } catch {}
  }, [landingDetails]);

  return (
    <div className="flex relative flex-col bg-bluePrimary">
      {animationVisibility && (
        <div className="absolute w-full h-full lg:flex">
          <ParticleAnimation />
        </div>
      )}
      <div className="absolute w-full h-full hidden lg:flex">
        {constants.reusableConstants.videoFormats.includes(backgroundType) ? (
          <video
            src={backgroundImage}
            className="h-full w-full object-cover object-center opacity-[20%]"
            autoPlay
            muted
            loop
            playsInline
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : !constants.reusableConstants.videoFormats.includes(
            backgroundType || ""
          ) && backgroundType ? (
          <img
            src={backgroundImage}
            alt="background_logo"
            className="w-full h-full object-cover object-center opacity-[20%]"
          />
        ) : (
          ""
        )}
      </div>
      <Navbar />
      <div className="flex  w-full linear_bg_Class z-[1] justify-center ">
        <div className="w-full items-center flex  flex-col px-[24px] pb-[3.125rem] max-w-[1920px] lg:items-start lg:px-homepagePaddingLg xl:px-homepagePadding2xl ">
          <MainLandingDetails
            appearControls={appearControls}
            changed={changed}
            heading={landingDetails[currentIndex]?.title}
            description={landingDetails[currentIndex]?.description}
            buttonText={buttonText}
            buttonFunction={() => {
              navigate(buttonURL);
            }}
          />
          <div className="w-full grid grid-cols-12 mt-10 gap-y-5 lg:mt-[150px] lg:gap-y-10 lg:gap-x-10">
            <MainLandingCard
              key={"LATEST"}
              heading={"LATEST"}
              description={blogDetails?.title}
              buttonText={"READ MORE"}
              path={`/resources/blogs/${replaceSpace(
                blogDetails?.title || ""
              )}/${blogDetails?.id}`}
            />
            <MainLandingCard
              key={"INDUSTRY RECOGNITION"}
              heading={"INDUSTRY RECOGNITION"}
              description={pressReleaseDetails?.title}
              buttonText={"READ MORE"}
              path={`/company/press-releases/${replaceSpace(
                pressReleaseDetails?.title || ""
              )}/${pressReleaseDetails?.id}`}
            />
            <MainLandingCard
              key={"ADVISORY"}
              heading={"ADVISORY"}
              description={advisoryReportDetails?.title}
              buttonText={"DOWNLOAD REPORT"}
              buttonFunction={() => {
                dispatch(
                  setSelectedURL(
                    process.env.REACT_APP_URL +
                      advisoryReportDetails?.reportPdf?.url
                  )
                );
                dispatch(setFormPopupState(true));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLandingComponent;
